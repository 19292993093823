import Analytics from 'analytics';
import React, { useEffect, useState } from 'react';

import AnalyticsContext from '@/contexts/AnalyticsContext';
import { useUser } from '@auth0/nextjs-auth0/client';
import nextConfig from '@/next.config';

const SEGMENT_WRITE_KEY = nextConfig.env.SEGMENT_WRITE_KEY as string;
const analytics = Analytics(SEGMENT_WRITE_KEY);

const AnalyticsProvider = ({ children }: any) => {
  const { isLoading, user } = useUser();

  const [auth0Id, setAuth0Id] = useState<string>();

  useEffect(() => {
    if (!isLoading && user?.sub && !auth0Id) {
      setAuth0Id(user.sub);
    }
  }, [isLoading, user, auth0Id]);

  const identify = async ({ traits }: { traits?: object }): Promise<void> => {
    return new Promise((resolve) => {
      const identifyParams = auth0Id
        ? { userId: auth0Id }
        : { anonymousId: 'anonymous' };
      resolve(
        analytics.identify({
          ...identifyParams,
          traits,
        }),
      );
    });
  };

  const page = async ({
    category = 'subscriptions dashboard',
    name,
    properties,
  }: {
    category?: string;
    name: string;
    properties?: object;
  }): Promise<void> => {
    return new Promise((resolve) => {
      resolve(
        analytics.pageview({
          Auth0Id: `${auth0Id}`,
          category,
          name,
          properties,
        }),
      );
    });
  };

  const track = async (event: string, properties?: object): Promise<void> => {
    return new Promise((resolve) => {
      resolve(analytics.track({ Auth0Id: `${auth0Id}`, event, properties }));
    });
  };

  return (
    <AnalyticsContext.Provider value={{ identify, page, track }}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export default AnalyticsProvider;
