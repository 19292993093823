import { createContext } from 'react';

const identify = async ({ traits }: { traits?: object }): Promise<void> => {
  console.log({ traits });
  return new Promise((resolve) => {
    resolve();
  });
};
const page = async ({
  category,
  name,
  properties,
}: {
  category?: string;
  name: string;
  properties?: object;
}): Promise<void> => {
  console.log({ category, name, properties });
  return new Promise((resolve) => {
    resolve();
  });
};
const track = async (event: string, properties?: object): Promise<void> => {
  console.log({ event, properties });
  return new Promise((resolve) => {
    resolve();
  });
};
const AnalyticsContext = createContext<{
  identify: typeof identify;
  page: typeof page;
  track: typeof track;
}>({
  identify,
  page,
  track,
});

export default AnalyticsContext;
