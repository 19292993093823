import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import type { AppProps } from 'next/app';
import type { ReactElement, ReactNode } from 'react';
import type { NextPage } from 'next';
import Head from 'next/head';
import { store } from '../stores/store';
import { UserProvider } from '@auth0/nextjs-auth0/client';
import { ChakraProvider } from '@chakra-ui/react';
import { Provider } from 'react-redux';
import AnalyticsProvider from '@/providers/AnalyticsProvider';
import { useAnalytics } from '@/hooks/useAnalytics';
import theme from '@/theme';

export type NextPageWithLayout<P = Record<string, unknown>, IP = P> = NextPage<
  P,
  IP
> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function AnalyticsWrapper({ children }: { children: ReactNode }) {
  const router = useRouter();

  const { page } = useAnalytics();
  useEffect(() => {
    const handleRouteChange = (url: string) => {
      page({
        category: 'Subscriptions Dashboard',
        name: url,
      });
    };
    router.events.on('routeChangeStart', handleRouteChange);
    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router, page]);
  return children;
}

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const { user } = pageProps;
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout || ((page) => page);

  const title = `Subscriptions Dashboard`;
  const description =
    'HealthTap.ai - Manage client subscriptions, organizations, homes, referral criteria and user logins.';
  const url = 'https://subscription-dashboard-client.azurewebsites.net/';
  return (
    <Provider store={store}>
      <UserProvider
        user={user}
        profileUrl="/api/auth/me"
        loginUrl="/api/auth/login"
      >
        <ChakraProvider theme={theme}>
          {getLayout(
            <>
              <Head>
                <meta name="description" content={description} />

                <meta property="og:url" content={url} />
                <meta
                  property="og:site_name"
                  content="Subscriptions Dashboard"
                />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:title" content={title} />
                <meta property="twitter:description" content={description} />
              </Head>
              <AnalyticsProvider>
                <AnalyticsWrapper>
                  <Component user={user} {...pageProps} />
                </AnalyticsWrapper>
              </AnalyticsProvider>
            </>,
          )}
        </ChakraProvider>
      </UserProvider>
    </Provider>
  );
}

export default MyApp;
