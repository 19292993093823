require('dotenv').config()

// azure keyvault secrets are written to secrets.json during 'npm postinstall'.
const vaultSecrets = require('./secrets.json') || {};
const secrets = vaultSecrets;
// Use local environment variable values for secrets  defined in .env files.
Object.keys(vaultSecrets).forEach((key) => {
  if(process.env[key]){
    vaultSecrets[key] = process.env[key];
  }
});

/** @type {import('next').NextConfig} */

const corsHeaders = [
  { key: 'Access-Control-Allow-Credentials', value: 'true' },
  { key: 'Access-Control-Allow-Origin', value: secrets.AUTH0_BASE_URL },
  { key: 'Access-Control-Allow-Origin', value: secrets.AUTH0_AUDIENCE },
  //{ key: "Access-Control-Allow-Origin", value: process.env.AUTH0_ISSUER_BASE_URL },
  { key: 'Access-Control-Allow-Methods', value: 'GET,DELETE,PATCH,POST,PUT' },
  {
    key: 'Access-Control-Allow-Headers',
    value:
      'X-CSRF-Token, X-Requested-With, Accept, Accept-Version, Content-Length, Content-MD5, Content-Type, Date, X-Api-Version',
  },
];
const nextConfig = {
  env: {
    ...secrets,
  },
  productionBrowserSourceMaps: !!process.env.GENERATE_SOURCEMAP,
  output: 'standalone',

  async headers() {
    return [
      {
        source: '/api/:path*',
        headers: corsHeaders,
      },
      {
        source: `/api/auth/:path*`,
        headers: corsHeaders,
      },
    ];
  },
  images: {
    unoptimized: true,
    remotePatterns: [
      {
        protocol: 'https',
        hostname: 'subscription-dashboard-cient.azurewebsites.net',
      },
    ],
  },
};

module.exports = nextConfig;
